@mixin modalTransition($what: all) {
  transition: $what 0.7s cubic-bezier(0.85, 0, 0.65, 1);
}

.modal {
  padding: ($space-unit * 4) 0;

  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // background: $content-white;
  height: var(--view-height);
  // max-width: calc(100vw - 2.4rem);
  // width: calc(25% - 2.4rem);
  & > .container {
    height: 100%;
    & > .row {
      height: 100%;
    }
  }

  overflow: auto;

  &__closearea {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  &.modal--no-padding {
    padding: 0;
  }

  &__container {
    // height: 100%;
    position: relative;
    background-color: $content-white;
  }

  &__content {
    // position: absolute;
    // top: 50%;
    // left: 0;
    // transform: translateY(-50%);
    width: 100%;
  }

  &__body {
    padding-bottom: 6rem;
  }

  &__title {
    margin-bottom: ($space-unit * 3);
    @extend %font__title;
  }

  &__buttonrow {
    margin-top: 4rem;
    button,
    .button {
      display: flex;
      width: 100%;
      margin-top: 2rem;
    }
  }

  // * DRAWER ----------------------------------------------------------------
  &--drawer {
    padding: 0;
    .modal__content {
      height: var(--view-height);
      overflow-y: auto;
    }

    &.ReactModal__Content {
      @include modalTransition(transform);
      transform: translateX(100%);

      &--after-open {
        @include modalTransition(transform);
        transform: translateX(0);
      }
      &--before-close {
        @include modalTransition(transform);
        transform: translateX(100%);
      }
    }
  }

  //fix focus
  &--dialog.modal {
    &:focus-visible {
      outline: none;
      .modal__container {
        @include focus-visible;
      }
    }
  }

  &.modal--centered {
    text-align: center;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 68.6rem;
    min-width: 40%;
  }

  &.modal--fullheight {
    height: var(--view-height);
    padding: 0;
    .modal__content {
      min-height: var(--view-height);
    }
  }

  &.modal--fullwidth {
    width: 100%;
    max-width: 100%;
    & > div {
      width: 100%;
    }
  }

  &.modal--minheight {
    min-height: 90vh;
    display: flex;
    align-items: stretch;
    .modal__body {
      width: 100%;
    }
  }

  &__header-title {
    display: flex;
    gap: $grid-gutter-width;
    align-items: baseline;
    margin-bottom: ($space-unit * 3);
    margin-top: 4rem;

    > .button {
      flex-grow: 0;
      transform: translateY(0.3rem);
    }
    h2 {
      margin: 0;
      flex-grow: 1;
    }
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: $grid-gutter-width;
    > .button {
      flex-grow: 1;
    }
  }
}

.modal__header {
  display: flex;
  // position: relative;
  flex-direction: column;
  // padding: ($space-unit * 15) 1.2rem 0;
}

.modal__divisor {
  width: 100%;
  @include border('top');
  &.noabs {
    position: relative;
  }
  margin: 4rem 0;
}

.dialog__message {
  // max-width: 29.3rem;
  margin-bottom: 2.4rem;
}

.dialog__title {
  @extend %font__title;
  //padding-top: ($space-unit * 8);
  margin: 5.6rem 0 1.2rem;
  //font-weight: 300;
}

.modal__close {
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  z-index: 3;
  width: 2.4rem;
  height: 2.4rem;
  &::before {
    content: '';
    @include fixedSprite('16-close');
    display: block;
  }
  &:focus-visible {
    outline: initial;
    &::before {
      outline: $outline-width solid $content-black;
      outline-offset: -1px;
    }
  }
  &--inverted {
    &::before {
      @include fixedSprite('16-close-white');
    }
    &:focus-visible {
      &::before {
        outline: $outline-width solid $content-white;
      }
    }
  }
}

.modal__intro {
  margin: ($space-unit * 6) 0;

  .status {
    display: block;
    margin-bottom: ($space-unit * 2);
  }
}

.overlay-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $overlay-modal;
  @include z-index(modal);

  &--drawer {
    /* custom */

    &.ReactModal__Overlay {
      background-color: rgba(0, 0, 0, 0);

      @include modalTransition();

      &--after-open {
        background-color: $overlay-modal;
        @include modalTransition();
      }
      &--before-close {
        background-color: rgba(0, 0, 0, 0);
        @include modalTransition();
      }
    }
  }
  // max-width: unset;
}

// .ReactModal__Overlay {
//   opacity: 0;
//   @include animate(0.4, 'opacity');
//   &--after-open {
//     opacity: 1;
//   }
//   &--before-close {
//     opacity: 0;
//   }
// }

.ReactModal__Content {
  transform: translateY(3.2rem);
  @include animate(0.4, transform);
  &--after-open {
    transform: translateY(0);
  }
  &--before-close {
    transform: translateY(3.2rem);
  }
}

.ReactModal__Content--after-open {
  transform: translateY(0); // https://jira.industries-group.com/browse/ONE-32708
}
