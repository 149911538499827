@import 'components/_*.default.scss';
@import 'layout/_*.default.scss';
@import 'pages/_*.default.scss';

// @media MIN

@include breakpoint(sm, min) {
  @import 'components/_*.sm-min.scss';
  @import 'layout/_*.sm-min.scss';
  @import 'pages/_*.sm-min.scss';
}

@include breakpoint(md, min) {
  @import 'components/_*.md-min.scss';
  @import 'layout/_*.md-min.scss';
  @import 'pages/_*.md-min.scss';
}

@include breakpoint(lg, min) {
  @import 'components/_*.lg-min.scss';
  @import 'layout/_*.lg-min.scss';
  @import 'pages/_*.lg-min.scss';
}

@include breakpoint(xl, min) {
  @import 'components/_*.xl-min.scss';
  @import 'layout/_*.xl-min.scss';
  @import 'pages/_*.xl-min.scss';
}

@include breakpoint(xxl, min) {
  @import 'components/_*.xxl-min.scss';
  @import 'layout/_*.xxl-min.scss';
  @import 'pages/_*.xxl-min.scss';
}

// @media BETWEEN

@media (min-width: get($grid-breakpoints, xs)) and (max-width: (get($grid-breakpoints, sm) - 1)) {
  @import 'components/_*.xs.scss';
  @import 'layout/_*.xs.scss';
  @import 'pages/_*.xs.scss';
}

@media (min-width: get($grid-breakpoints, sm)) and (max-width: (get($grid-breakpoints, md) - 1)) {
  @import 'components/_*.sm.scss';
  @import 'layout/_*.sm.scss';
  @import 'pages/_*.sm.scss';
}

@media (min-width: get($grid-breakpoints, md)) and (max-width: (get($grid-breakpoints, lg) - 1)) {
  @import 'components/_*.md.scss';
  @import 'layout/_*.md.scss';
  @import 'pages/_*.md.scss';
}

@media (min-width: get($grid-breakpoints, lg)) and (max-width: (get($grid-breakpoints, xl) - 1)) {
  @import 'components/_*.lg.scss';
  @import 'layout/_*.lg.scss';
  @import 'pages/_*.lg.scss';
}

@media (min-width: get($grid-breakpoints, xl)) and (max-width: (get($grid-breakpoints, xxl) - 1)) {
  @import 'components/_*.xl.scss';
  @import 'layout/_*.xl.scss';
  @import 'pages/_*.xl.scss';
}

// @media MAX

@include breakpoint(lg) {
  @import 'components/_*.lg-max.scss';
  @import 'layout/_*.lg-max.scss';
  @import 'pages/_*.lg-max.scss';
}

@include breakpoint(md) {
  @import 'components/_*.md-max.scss';
  @import 'layout/_*.md-max.scss';
  @import 'pages/_*.md-max.scss';
}

@include breakpoint(sm) {
  @import 'components/_*.sm-max.scss';
  @import 'layout/_*.sm-max.scss';
  @import 'pages/_*.sm-max.scss';
}

@include breakpoint(xs) {
  @import 'components/_*.xs-max.scss';
  @import 'layout/_*.xs-max.scss';
  @import 'pages/_*.xs-max.scss';
}
