.heading-tabs-checkout {
  @include checkoutLargeSpacing();
}

.wrapper-tab-list-checkout {
  .react-tabs__tab-list {
    display: flex;
    justify-content: center;
    padding-top: 0;
  }
}

.wrapper-tab-panels-checkout {
  padding-top: 5.2rem;
  padding-bottom: 16rem;
}
