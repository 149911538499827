:root {
  --header-scroll: 0px;
}

header {
  // position: relative;
  width: 100%;
  @include z-index('headerOpen');
  transform: translateY(0);
  // transition: top 0.3s ease-in-out;
  position: fixed;
  left: 0;
  top: 0;

  &.checkout {
    position: relative;
  }

  &:not(.pdp) {
    transform: translateY(var(--header-offset));
  }

  &.header {
    &--pdp {
      position: fixed;
      top: 0;
      & ~ main {
        .product-hero--wrapper {
          // padding-top: var(--header-h);
          margin-top: calc(var(--header-h) * 1);
        }
      }
    }
  }

  // * EDIT MODE -----------------------------------------------------------------
  &.editmode {
    background-color: $background-product;
    position: relative;
    .experience-menu_section {
      border: solid 2px red;
      &:not(:first-child) {
        margin-top: 6rem;
      }
    }
    .editmode {
      &--wrapper {
        padding: 2.4rem 0;
        max-width: 85%;
        margin: 0 auto;
      }
      &--menu-section-title {
        text-align: center;
        @extend %font__title;
        padding: 2.4rem;
        background-color: $interactive-light;
        color: $content-white;
        margin-bottom: 2.4rem;
      }
      &--row {
        margin-bottom: 4rem;
      }

      &--manual-list {
        list-style: none;
        li {
          @extend %font__title;
        }
      }

      &--modal-container {
        padding-top: 0;
        padding-bottom: 2.4rem;
        background-color: $content-white;
      }
    }
  }
}
.header {
  // * TOP BAR -----------------------------------------------------------------
  &-topbar {
    &__container {
      padding: 0.6rem 0 1.4rem;
      .container {
        display: flex;
        align-items: center;
      }
    }
    button {
      display: inline-flex;
      [data-whatintent='mouse'] &:focus-visible {
        outline-color: transparent;
      }
    }

    // * TRANSPARENCY -----------------------------------------------------------------
    background-color: $content-white;

    &[data-transparent='true'][data-open='false'] {
      background-color: transparent;
    }
    &[data-color='white'][data-open='false'] .header-topbar__container {
      button,
      .header-logo,
      img {
        filter: invert(100%);
      }
    }
  }

  // * MAIN NAV -----------------------------------------------------------------
  #mainnav {
    ul {
      padding: 0;
      list-style: none;
      li {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  // * LOGO -----------------------------------------------------------------
  &-logo {
    svg {
      height: 3.2rem;
      width: auto;

      &.logo_us {
        height: 4rem;
      }
    }
  }

  // * HEADER AREAS -----------------------------------------------------------------
  &-area {
    &__hamburger {
      margin-top: $space-unit * 2;
      flex: 1;
    }
    &__bag {
      margin-top: $space-unit * 2;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: $space-unit * 3;
    }
  }
}

//* MENU ----------------------------------------------------------------------
.menu {
  &-modal {
    position: absolute;
    top: calc(#{var(--header-h)} - #{var(--header-scroll)} - 1px);
    height: calc(var(--view-height) - var(--header-h));
    width: 100%;
    background-color: $content-white;
    @include z-index('header');

    > nav.container {
      flex: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      bottom: var(--nav-footer-h);
      overflow-y: auto;
      // height: calc(var(--view-height) - var(--header-h) - 12.4rem); //CUT OUT MENU MOBILE FOOTER
    }

    &--overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      @include z-index('header');
    }

    &__footer {
      padding-bottom: env(safe-area-inset-bottom);
      box-shadow: 0 1px 0 0 rgba(black, 0.1) inset;
      background-color: $content-white;
      position: fixed;
      left: 0;
      bottom: env(safe-area-inset-bottom);
      right: 0;

      @extend %slide-in;
      &[data-submenu-active='true'] {
        @extend %slide-right-out;
      }

      button,
      a {
        display: flex;
        width: 100%;
        text-align: left;
      }
    }

    &__container {
      height: 100%;
    }

    // //* ANIMATION ----------------------------------------------------------------------------------------------------------------------------------------
    &.ReactModal__Content {
      transform: translateY(0);

      &--after-open {
        transform: translateY(0);
      }
    }
  }

  &-close-button {
    position: fixed;
    background-color: $content-white;
    top: -4.2rem;
    left: 1.2rem;
    display: flex;
  }
  &-voice {
    @extend %font__cta;
    padding: 0;
    // display: block;
    // width: 100%;

    &:not(:last-child) {
      margin-bottom: $space-unit * 4;
    }

    &--country-selector {
      .header-countrybutton {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    a,
    button {
      text-decoration: none;
      padding: 0.4rem 0;
      @extend %font__cta;

      &[aria-current='true'],
      &[data-current='true'] {
        border-bottom: 0.1rem solid $content-black;
      }

      &[aria-expanded='true'] {
        cursor: inherit;
      }
      // &[aria-current='true'] {
      //   text-decoration: underline;
      // }
    }

    &.sale {
      & > button,
      & > a {
        color: $alert-error;
        border-bottom-color: $alert-error;
      }
    }

    &__link,
    &__nav-title {
      display: block;
      width: 100%;
      text-align: left;
      @extend %slide-in;
      [data-submenu-active='true'] & {
        @extend %slide-right-out;
      }

      &.sale {
        color: $alert-error;
      }
    }

    &__nav {
      position: static;

      &:first-child {
        padding-top: 2.9rem;
      }
      &:not(:last-child) {
        margin-bottom: 4rem;
      }

      &-title {
        padding: $space-unit 0;
      }
    }

    &__list {
      padding: 0 $space-unit * 5;
      margin-top: $space-unit * 4;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      list-style: none;
    }
  }

  &-heading {
    @extend %font__cta;
  }
}

//* SUBMENU ----------------------------------------------------------------------
.submenu {
  &__wrapper {
    position: fixed;
    top: 0;
    bottom: var(--nav-footer-h);
    left: 0;
    width: 100vw;
    overflow-y: auto;
    // height: calc(var(--view-height) - var(--header-h) - 12.4rem);
    background-color: $content-white;
    @include z-index('modal');
    @extend %slide-left-out;

    &[data-active='true'] {
      @extend %slide-in;
    }
  }
  &-back-button {
    margin-top: 0.6rem;
    padding: $space-unit 0;
  }
  &-title {
    @extend %font__title;
    margin-top: $space-unit * 10;
    padding: $space-unit 0;
  }
  &-voice {
    margin-top: $space-unit * 4;
    &.sale {
      color: $alert-error;
      border-bottom-color: $alert-error;
      a {
        &[aria-current='true'] {
          border-bottom: solid 0.1rem $alert-error;
        }
      }
    }

    button,
    a {
      &[aria-current='true'] {
        border-bottom: solid 0.1rem $content-black;
      }
    }

    &__list {
      padding: 0 $space-unit * 5;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &:not(:nth-of-type(1)) {
        margin-top: $space-unit * 9;
      }
    }
  }
}

%slide-left-out {
  transition: all 0.4s cubic-bezier(0.25, 1, 0.25, 1);
  visibility: hidden;
  transform: translateX(50vw);
  opacity: 0;
}

%slide-right-out {
  transition: all 0.4s cubic-bezier(0.25, 1, 0.25, 1);
  visibility: hidden;
  transform: translateX(-50vw);
  opacity: 0;
}

%slide-in {
  transition: all 0.4s cubic-bezier(0.25, 1, 0.25, 1);
  visibility: visible;
  transform: translateX(0%);
  opacity: 1;
}
