.client-service {
  .items-container {
    margin-top: 4rem;

    .bag-product {
      &__image {
        max-width: 10.4rem;
        padding: 0;
      }

      &__content {
        justify-content: space-between;
        padding-left: 1.2rem;
        .option-product__label {
          @extend %font__title;
        }
      }

      &__name-price {
        overflow-wrap: break-word;
        &__name {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 36.5rem;
        }

        &__price {
          text-align: right;
          flex-shrink: unset;

          .price {
            @extend %font__title;
            white-space: nowrap;
          }
          .returnabledisclaimer {
            margin-top: 0.4rem;

            p {
              @extend %font__subtitle;
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
      &__details {
        margin-bottom: 0;
      }

      &__reasons-select {
        margin-top: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .form-control textarea {
          height: 16rem;
          border: solid 0.1rem $content-black;
          @media screen and (min-width: map-get($grid-breakpoints, lg)) {
            height: 20rem;
          }
        }
      }

      &__reasons-detail {
        margin-top: 3.2rem;
        @media screen and (min-width: map-get($grid-breakpoints, lg)) {
          margin-top: 4rem;
        }
        .reasons-detail__title {
          @extend %font__subtitle;
        }
      }
    }

    .optionbox {
      margin: 0;
      gap: 2rem;

      &__item {
        &__content {
          padding: 1.2rem;
        }
      }
      input {
        &::after {
          content: none;
        }
      }
    }
  }

  .items--form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5.2rem;
    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      gap: 6rem;
    }
  }

  .items-step__btn-container {
    margin-top: 6rem;
    row-gap: 2rem;
    align-items: center;
  }

  .items-step__changeyourmind {
    margin-top: 4rem;
    p {
      margin-bottom: 2rem;
    }
  }

  .items-container__exchanging-options {
    padding-top: 4rem;
    padding-bottom: 6rem;

    .fieldset--plain {
      margin: 0;
    }

    .bag-product__reasons-select {
      gap: 4rem;
    }
    .items-container__reason-select {
      .form-group {
        label {
          padding: 0;
        }
      }
    }
  }

  #reasonPanel {
    .bag-product {
      margin: 0;
      padding: 0;
      border: unset;
    }
  }

  .return-summary {
    &__subtitle {
      @extend %font__title;
      margin-bottom: 2rem;
    }

    &__summary {
      margin-bottom: 4rem;
    }

    &__content {
      margin-top: 4rem;
      padding: 4rem 0;
      border-top: solid 0.1rem $outline;
      border-bottom: solid 0.1rem $outline;
    }

    &__returns-general-info {
      padding: 4rem 0;
      border-bottom: solid 0.1rem $outline;
      row-gap: 2.4rem;

      @media screen and (min-width: map-get($grid-breakpoints, lg)) {
        row-gap: 4rem;
      }

      .dt {
        display: block;
        @extend %font__title;
        margin-bottom: 0.4rem;
      }
    }

    .items-container {
      gap: 5.2rem;
      margin-top: 0;

      .bag-product {
        border: none;
        margin: 0;
        padding: 0;
      }

      @media screen and (min-width: map-get($grid-breakpoints, lg)) {
        gap: 6rem;
      }
    }
  }

  &__return-how-to {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &--row {
      row-gap: 5.2rem;
    }

    .title {
      margin-bottom: 2rem;
    }

    .description {
      flex: 1;
      margin-bottom: 2rem;
    }
  }

  @include breakpoint(lg, min) {
    &__return-how-to {
      .description {
        margin-bottom: 3.2rem;
      }
    }
  }
}
