#search-results {
  margin-top: var(--header-h);
}

.search {
  &-modal {
    .modal__content {
      padding: 0;
    }
    .modal__body {
      height: 100%;
      padding-top: 6.9rem;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
    }

    &__form {
      @extend %container;

      .invalid-feedback {
        color: $content-black;
        margin-top: 2rem;
      }
    }

    &__content {
      padding-top: 6rem;
      flex: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2.4rem;
    }
  }
  &-suggestions {
    @extend %container;

    &__title {
      @extend %font__title;
      margin-bottom: 2rem;
    }

    &__list {
      padding: 0;
      list-style: none;
    }

    &__suggestion {
      padding: 0;
      a {
        @extend %font__cta;
      }

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  &-promocards {
    &__list {
      padding-bottom: 2.4rem;
      @extend %container;
      list-style: none;
      display: flex;
      gap: 1.2rem;
      overflow-x: scroll;
      overflow-y: hidden;
      // align-items: stretch;
      // flex: 1;
    }
    &__card {
      padding: 0;
      min-width: 14.1rem;
      width: 100%;
      img {
        min-width: 14.1rem;
        // height: 100%;
      }
    }
  }

  &-noresults {
    @extend %container;
    &__title {
      @extend %font__title;
      margin-bottom: 1rem;
    }
  }

  &-contacts {
    padding: 0;
    list-style: none;

    &__list {
      padding: 0;
    }

    &__item {
      padding: 0;
      list-style: none;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .button {
        width: 100%;
      }
    }
  }

  &-close-button {
    position: fixed;
    background-color: $content-white;
    top: -4rem;
    right: 1.2rem;
    display: flex;

    .ReactModal__Content & {
      transform: translateY(-3.2rem);
      @include animate(0.4, 'transform');
    }

    .ReactModal__Content--after-open & {
      transform: translateY(0); // https://jira.industries-group.com/browse/ONE-32708
    }
  }
}

// * SEARCH PAGE --------------------------------------------------------------------
.search-page {
  &-header {
    height: 19.5rem;
    display: flex;
    align-items: center;
    &--title {
      @extend %font__subtitle;
      display: flex;
      flex-direction: column;
      gap: 0.9rem;
    }
  }
}

// ANCHOR - NO RESULTS --------------------------------------------------------------------
.no-results {
  &-content {
    padding: 7.5rem 0 8rem;
  }

  &-carousel {
    .productTiles-rail {
      padding-top: 0;
    }
  }

  &__suggestions {
    h3 {
      margin-bottom: 1.6rem;
    }
    ul {
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      li {
        padding: 0;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    margin-bottom: 2.9rem;
    &--wrapper {
      margin-bottom: 5.2rem;
    }
  }
  &__description {
    margin-bottom: 4rem;
  }
}
