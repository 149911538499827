.modal {
  padding: 16rem 0;
  align-items: flex-start;

  &__wrapper {
    @include absolute-col(16);
  }

  &__content {
    margin: auto;
    @include absolute-col(14);
  }

  .button-row {
    margin: 4rem 0 0;
  }

  &.modal--fullheight {
    height: 100vh;
    padding: 6rem 0;

    .modal__content {
      @include vh(min-height, 68, 1rem);
    }
  }

  &__buttons-wrapper {
    justify-content: space-between;
  }

  // * DRAWER ----------------------------------------------------------------
  &--drawer {
    padding: 0;
    position: absolute;
    width: 49rem;
    right: 0;
    height: 100vh;
    .modal__content {
      height: 100vh;
    }
  }

  // * IMAGE ----------------------------------------------------------------
  &--image {
    padding: 0;
    &.modal--fullheight {
      .modal__content {
        min-height: 0;
      }
    }
    .modal__content {
      display: flex;
      @include absolute-col(16);
    }
    .modal__body {
      display: flex;
      padding: 0;
      width: 100%;
      &--left {
        padding: 2rem 0 2rem 2rem;
        img {
          max-width: 36rem;
          width: 36rem;
          height: 541px;
          opacity: 0;
          @include animate(0.4, 'opacity');
          &.lazyloaded {
            width: auto;
            height: auto;
            opacity: 1;
          }
        }
      }
      &--right {
        height: 100%;
        position: relative;
        flex: 1;
        overflow-y: auto;
        .scrollable {
          position: absolute;
          padding: 4rem 4rem 2rem;
          width: 100%;
        }
      }
    }

    .dialog__title {
      margin-top: 0;
    }
  }
}

//ANCHOR - CUSTOMIZE SINGLE MODALS
.modal {
  &#notifyme {
    padding-top: 6rem;
  }

  &#contact-us {
    padding-top: 6rem;
    .modal__divisor {
      margin: 6rem 0;
    }
    .getintouch {
      &__wrapper {
        gap: 0;
      }
      &__row {
        display: block;
      }
    }
  }
}
