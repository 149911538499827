// SIZE SELECTOR USED IN: cart
.selector-size-inline {
  position: relative;
  height: 2.4rem;
  color: $content-black;
  @include animate(0.5, height);

  .selector__button {
    position: relative;
    padding-right: $space-unit * 6;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    line-height: 2.4rem;
    // @extend %primary-xs;
    // alert: do not change, it may affect focus management
    // @include animate(0.5, 'opacity');

    &__value {
      .bag-product & {
        display: inline-block;
        position: relative;
        margin-left: 1.1rem;
        min-width: 2.4rem;
        text-align: center;
      }
      &--outofstock {
        color: $disabled-dark;
        // @include strokeThrough;
      }
    }

    &::after {
      content: '';
      right: 0;
      @include fixedSprite('10-open');
      position: absolute;
      top: -0.1rem;
    }

    &:disabled {
      color: $content-black;
      &::after {
        display: none;
      }
    }

    &[aria-expanded='true'] {
      @extend .visually-hidden;

      + .selector__menu {
        opacity: 1;
        visibility: visible;
      }
    }

    &__value--outofstock {
      color: $disabled-dark;
    }

    &__info {
      margin-left: 1.2rem;
      color: $disabled-dark;
    }
  }

  .selector__menu {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    align-items: flex-start;
    outline: none;
    line-height: 2.4rem;
    // alert: do not change, it may affect focus management
    // @include animate(0.5, 'opacity');

    .label {
      // @extend %primary-xs;
      margin: 0 2.4rem 0 0;
    }

    fieldset {
      display: flex;
      flex-wrap: wrap;
      // display: grid;
      // bottom: initial;
      // grid-template-columns: repeat($selector-size-inline-cols-desktop, 1fr);
      row-gap: $selector-size-inline-rowgap-desktop;
      flex: 1;

      input[type='radio'],
      input[type='checkbox'] {
        appearance: initial;
        -webkit-appearance: initial;
        margin: initial;
        border: initial;
        outline: none;
        width: 0;
        height: 0;

        + label {
          @include touchtarget-area();
          cursor: pointer;
        }

        &:focus {
          & + label {
            @include touchtarget-focus();
          }
        }
      }
    }

    &__item {
      // @extend %primary-xs;
      margin: 0 2.4rem 0 0;
      position: relative;

      &.is-disabled {
        cursor: default;
        .selector__menu__item__label {
          color: $disabled-light;
          @extend %font__strike;
        }
      }

      &.selected {
        .selector__menu__item__label {
          color: $content-black;
          @extend %font__cta--ul;
        }
      }
    }
  }

  .selector__button.expanded {
    opacity: 0;
    & + .selector__menu {
      visibility: visible;
      opacity: 1;
    }
  }

  .selector-wrapper {
    @include reset-list;
    display: flex;

    .selector__menu__item:first-child,
    li:first-child {
      margin-left: $space-unit;
    }
  }
}
