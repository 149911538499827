.modal {
  &__wrapper {
    @include absolute-col(12);
  }

  &__content {
    @include absolute-col(10);
  }

  &.modal--fullheight {
    padding: 10rem 0;
    .modal__content {
      @include vh(min-height, 70.5, 1rem);
    }
  }

  // * DRAWER ----------------------------------------------------------------
  &--drawer {
    width: 57rem;
  }

  // * IMAGE ----------------------------------------------------------------
  &--image {
    &.modal--fullheight {
      .modal__content {
        min-height: 0;
      }
    }

    .modal__body {
      &--left {
        img {
          max-width: 40.6rem;
          width: 40.6rem;
          height: 610px;
        }
      }
      &--right {
        .scrollable {
          padding: 4rem 6.4rem 2rem;
        }
      }
    }

    .modal__content {
      @include absolute-col(12);
    }
  }
}

//ANCHOR - CUSTOMIZE SINGLE MODALS
.modal {
  &#notifyme {
    padding-top: 16.5rem;
  }
  &#contact-us {
    padding-top: 25.1rem;
    .modal__divisor {
      margin: 4rem 0;
    }
  }
}
